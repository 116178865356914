<template>
  <div class="sub_container">
    <div class="sub_wrapper">
      <h2 class="sub-title1">{{ $t('menu2-detail') }}</h2>
      <el-row>
        <el-card class="box-card">
            <div class="sub_table el-table-m">
              <dl class="display_detail">
                <dt>{{ $t('columnBlockNo') }}</dt>
                <dd> #{{blockData.block_nm}}</dd>
                <dt>{{ $t('columnTime') }}</dt>
                <dd>{{blockData.reg_dt}} UTC</dd>
                <dt>{{ $t('columnNumTransactions') }}</dt>
                <dd>{{blockData.num_txs}}</dd>
                <dt>{{ $t('columnBlockHash') }}</dt>
                <dd>
                  <div class="d-flex align-items-center">
                    <span class="block_hash_detail_txt">{{blockData.block_hash}}</span>
                    <span class="ico_copy"
                      @click="$func.copy_to_clipboard(blockData.block_hash);
                      $message({message: $t('msgCopy'), type: 'success' });">
                      copy
                    </span>
                  </div>
                </dd>
                <dt>{{ $t('columnValidatorId') }}</dt>
                <span @click="detailClick(blockData)" class="tdlink">{{blockData.moniker}}</span>
              </dl>
            </div>
            <el-descriptions class="margin-top el-table" title="" :column="1"  border>
                <colgroup>
                  <col width="30%">
                  <col width="70%">
                </colgroup>
                <el-descriptions-item>
                  <template slot="label">
                    {{ $t('columnBlockNo') }}
                  </template>
                  #{{blockData.block_nm}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                      {{ $t('columnTime') }}
                  </template>
                  {{blockData.reg_dt}} UTC
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                        {{ $t('columnNumTransactions') }}
                  </template>
                  {{blockData.num_txs}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                        {{ $t('columnBlockHash') }}
                  </template>
                  <div class="d-flex align-items-center">
                    <span class="block_hash_txt hash_txt_tbl">{{blockData.block_hash}}</span>
                    <span class="ico_copy"
                      @click="$func.copy_to_clipboard(blockData.block_hash);
                      $message({message: $t('msgCopy'), type: 'success' });">
                      copy
                    </span>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    {{ $t('columnValidatorId') }}
                  </template>
                  <span @click="detailClick(blockData)" class="tdlink">{{blockData.moniker}}</span>
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <h2 class="sub-title2">
            {{ $t('menu3-1') }}
        </h2>
        <!-- List //-->
        <STtable  :columnes="columnes_data"
                          :tableData="tableData_data"
                          :total_data = "total_data"
                          :loading="loading">
        </STtable>
      </el-row>
    </div>
  </div>
</template>
<script>
import STtable from '@/components/STtable.vue';

export default {
  name: 'blocksDetail',
  components: {
    STtable,
  },
  data() {
    return {
      loading: false,
      columnes_data: [
        {
          label: 'columnResult', prop: 'tx_status', width: '10%', class: '', router: '', key: '',
        },
        {
          label: 'columnTxHash', prop: 'tx_id', width: '15%', class: '', router: 'transactionDetail', key: 'tx_id',
        },
        {
          label: 'columnTxType', prop: 'tx_type', width: '15%', class: '', router: '', key: '',
        },
        {
          label: 'columnAmount', prop: 'amount', width: '15%', class: '', router: '', key: '',
        },
        {
          label: 'columnTxFee', prop: 'gas_fee', width: '10%', class: '', router: '', key: '',
        },
        {
          label: 'columnHeight', prop: 'block_nm', width: '10%', class: '', router: 'blocksDetail', key: 'block_nm',
        },
        {
          label: 'columnTime', prop: 'time_ago', width: '*', class: '', router: '', key: '',
        },
      ],
      tableData_data: [],
      total_data: 0,
      blockData: {},
      blockId: '',
    };
  },
  methods: {
    detailClick(data) {
      this.$router.push({
        name: 'validatorDetail', params: { me: data.operator_addr },
      });
    },
    async getBlock() {
      try {
        const { data } = await this.$axios.get(`/blocks/${this.blockId}`);
        if (data.ret_code === 200) {
          this.blockData = data.data.block;
          this.tableData_data = data.data.txs;
        } else {
          this.$message.error(`${this.blockId}: ${this.$t('411codeMessage')}`);
          this.$router.push({ name: 'blocks' });
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
  created() {
    this.blockId = this.$route.params.me;
    this.getBlock();
  },
  mounted() {
  },
};
</script>
